import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { PhoneInput, COUNTRIES } from "baseui/phone-input";
import CustomFlag from './countryFlag';
import { api } from '../../services/api';

const User = forwardRef((props, ref) => {
  const { updateData } = props;
  const [nif, setNIF] = useState(props.NIF ?? '');
  const [nome, setNome] = useState(props.Name ?? '');
  const [morada, setMorada] = useState(props.Morada ?? '');
  const [telemovel, setTelemovel] = useState(props.Mobile ?? '');
  const [email, setEmail] = useState(props.Email ?? '');
  const [countrytelemovel, setCountryTelMov] = React.useState(COUNTRIES.PT);
  const [isRegistered, setIsRegistered] = useState(props.isRegistered ?? false);

  useImperativeHandle(ref, () => ({
    resetControls() {
      setNIF("");
      setNome("");
      setMorada("");
      setTelemovel("");
      setEmail("");
      setCountryTelMov(COUNTRIES.PT);
      setIsRegistered(false);
    }
  }));

  function handleNIFBlur(e) {
    updateData({ NIF: e.currentTarget.value });

    api.get("/userInfo?nif=" + nif)
      .catch(err => console.log(err))
      .then((response) => {
        setIsRegistered(response.data.IsRegistered);
      });
  }

  useEffect(() => {
    updateData({ isRegistered: isRegistered ?? false });
  }, [isRegistered]);

  return (
    <React.Fragment>
      <FormControl label={() => "NIF"}>
        <Input
          value={nif}
          onChange={e => setNIF(e.currentTarget.value)}
          onBlur={e => handleNIFBlur(e)}
          required
        />
      </FormControl>
      {isRegistered === true ? (
        <p>Já temos informações sobre si. Não necessita de preencher os restantes dados pessoais.</p>
      ) : (
        <>
          <FormControl label={() => "Nome"}>
            <Input
              value={nome}
              onChange={e => setNome(e.currentTarget.value)}
              onBlur={e => updateData({ Name: e.currentTarget.value })}
              required
            />
          </FormControl>
          <FormControl label={() => "Morada"}>
            <Input
              value={morada}
              onChange={e => setMorada(e.currentTarget.value)}
              onBlur={e => updateData({ Morada: e.currentTarget.value })}
            />
          </FormControl>
          <small>
            Formato da Morada: Rua 25 de Abril de 1974, 1 2A, 1234-567 Localidade
          </small>
          <FormControl label={() => "Telemóvel"}>
            <PhoneInput
              required
              value={telemovel}
              country={countrytelemovel}
              onTextChange={e => setTelemovel(e.currentTarget.value)}
              onBlur={e => updateData({ Mobile: e.currentTarget.value })}
              onCountryChange={e => setCountryTelMov(e.option)}
              overrides={{
                FlagContainer: {
                  component: CustomFlag,
                },
              }}
            />
          </FormControl>
          <FormControl label={() => "Email"}>
            <Input
              value={email}
              type="email"
              required
              onChange={e => setEmail(e.currentTarget.value)}
              onBlur={e => updateData({ Email: e.currentTarget.value })}
            />
          </FormControl>
        </>
      )}
    </React.Fragment>
  )
});

User.displayName = "User";

export default User;