import React, { useContext, useRef, useState } from "react";
import { Button, KIND } from "baseui/button";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from "baseui/modal";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { StyledLink } from "baseui/link";
import { Drawer } from "baseui/drawer";
import { Tabs, Tab } from "baseui/tabs-motion";
import { Textarea } from "baseui/textarea";
import { ParishContext } from "../../components/Map/parishProvider";
import Upload from "./upload";
import User from "./user";
import ClassificationList from './ClassificationsList';
import PrivacidadeContent from "../../components/PrivacidadeContent";
import { api } from "../../services/api";
import { emailIsValid, nifIsValid } from "../../services/helpers";
import { geocodeFromLatLngToAdr } from "../../services/addresses";

function CreateOcorrency() {
  const { Name } = useContext(ParishContext);
  const childRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  const [data, setData] = useState({});
  const [obs, setObs] = useState('');
  const [images, setImages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checked, setChecked] = React.useState(false);

  function handleCreateOcorrencyButtonClick(e) {
    if (window.markers.length === 0) {
      alert("Selecione uma localização antes de continuar.");
      return;
    }

    setIsOpen(true);
  }

  function updateData(toMerge) {
    var _data = Object.assign({}, data, toMerge);
    setData(_data);
  }

  function updateImages(images) {
    setImages(images);
  }

  function validateForm() {
    let msg = '';

    if (!data.NIF || data.NIF.length === 0) {
      msg += "O campo 'NIF' é de preenchimento obrigatório.\n";
    } else {
      if (!nifIsValid(data.NIF)) {
        msg += "O campo 'NIF' nao é válido.\n";
      }
    }

    if (!data.isRegistered) {
      if (!data.Name || data.Name.length === 0) {
        msg += "O campo 'Nome' é de preenchimento obrigatório.\n";
      }

      if (!data.Email || data.Email.length === 0) {
        msg += "O campo 'Email' é de preenchimento obrigatório.\n";
      } else {
        if (!emailIsValid(data.Email)) {
          msg += "O campo 'Email' nao é válido.\n";
        }
      }

      if (!data.Mobile || data.Mobile.length === 0) {
        msg += "O campo 'Telémovel' é de preenchimento obrigatório.\n";
      }
    }

    if (!data.IDClassification) {
      msg += "O campo 'Assunto' é de preenchimento obrigatório.\n";
    }

    if (!data.Obs || data.Obs.length === 0) {
      msg += "O campo 'Observações' é de preenchimento obrigatório.\n";
    }

    if (msg.length > 0) {
      alert(`Preencha o(s) campo(s) antes de continuar:\n\n${msg}`);
      return false;
    }

    return true;
  }

  function geocodeAdrToLatLng(addr) {
    const promise = new Promise((resolve, reject) => {
      api.get(`https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDmGMREGfKSIFk8HGqdMXzT4lXI7iKNqRk&address=${encodeURIComponent(addr)}`)
        .catch((err) => reject(err))
        .then((response) => {
          resolve(response.data.results[0]);
        });
    });

    return promise;
  }

  function formatAddressComponents(address) {
    var components = Object.create({});

    address.forEach((component, i) => {
      Object.defineProperty(components, component.types[0], {
        value: component.short_name
      });
    });

    return components;
  }

  function resetForm() {
    setIsSubmitting(false);
    setIsOpen(false);
    childRef.current.resetControls();
    setImages([]);
    setData({});
    setObs("");
    setChecked(false);
  }

  function sentWithSuccess() {
    alert("A sua ocorrência foi submetida com sucesso.");
    setIsOpen(false);
    resetForm();
  }

  async function submitOcorrency() {
    if (!validateForm() || !window.markers[0]) {
      return;
    }

    setIsSubmitting(true);

    let __PINLATITUDE = window.markers[0].position.lat();
    let __PINLONGITUDE__ = window.markers[0].position.lng();

    let morada = {};
    if (data.Morada && data.Morada !== "") {
      morada = await geocodeAdrToLatLng(data.Morada);
      morada = formatAddressComponents(morada.address_components);
    }

    let local = {};
    local = await geocodeFromLatLngToAdr(__PINLATITUDE, __PINLONGITUDE__);
    local = formatAddressComponents(local.address_components);

    let _data = [data].map(x => {
      return {
        Name: (x.Name ?? ""),
        Address: (morada.formatted_address ?? "").replace((morada.postal_code ?? ""), ""),
        ZipCode: (morada.postal_code ?? ""),
        Local: (local.route ?? ""),
        Door: `${local.street_number ?? ""}`,
        Locality: (local.locality ?? ""),
        NContrib: x.NIF,
        NEleitor: '',
        Phone: (x.Mobile ?? ""),
        Mobile: (x.Mobile ?? ""),
        Email: (x.Email ?? ""),
        IDClassification: x.IDClassification,
        Obs: x.Obs,
        X: `${__PINLATITUDE}`,
        Y: `${__PINLONGITUDE__}`
      }
    })[0];

    api.post("/ocorrencies/submit2", _data)
      .catch(err => {
        alert("Não foi possivel enviar a ocorrência. Ocorreu um erro no servidor.")
        setIsSubmitting(false);
      })
      .then(response => {
        if (images && images.length > 0) {
          api.post("/upload?id=" + response.data, images, { headers: { "content-type": "multipart/form-data" } })
            .catch(err => console.log(err.message))
            .then(response => {
              sentWithSuccess();
            })
        } else {
          sentWithSuccess();
        }
      });
  }

  return (
    <React.Fragment>
      <Button onClick={(e) => handleCreateOcorrencyButtonClick(e)}>Criar Ocorrência</Button>
      <Modal closeable isOpen={isOpen} animate autoFocus size={SIZE.default} role={ROLE.dialog} onClose={() => setIsOpen(false)}>
        <ModalHeader>Criar Ocorrência</ModalHeader>
        <ModalBody>
          <Tabs activeKey={activeKey} activateOnFocus onChange={({ activeKey }) => { setActiveKey(activeKey); }}>
            <Tab title="Dados Pessoais">
              <User isRegistered={data.isRegistered} NIF={data.NIF} Name={data.Name} Morada={data.Morada} Mobile={data.Mobile} Email={data.Email} updateData={updateData} ref={childRef} />
              <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)} labelPlacement={LABEL_PLACEMENT.right}>Aceito o <StyledLink href="#" onClick={() => setIsDrawerOpen(true)}>contrato RGPD</StyledLink> proposto pela {Name}.</Checkbox>
            </Tab>
            <Tab title="Assunto">
              <ClassificationList IDClassification={data.IDClassification} updateData={updateData} />
            </Tab>
            <Tab title="Observações">
              <Textarea rows={10} value={obs} clearOnEscape onChange={e => setObs(e.target.value)} onBlur={e => updateData({ Obs: e.target.value })} />
            </Tab>
            <Tab title="Anexos">
              <Upload defaultImages={images} updateImages={updateImages} />
            </Tab>
          </Tabs>
          <Drawer isOpen={isDrawerOpen} autoFocus onClose={() => setIsDrawerOpen(false)}>
            <PrivacidadeContent />
          </Drawer>
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={KIND.tertiary} onClick={() => resetForm()}>Sair</ModalButton>
          <ModalButton onClick={() => submitOcorrency()} disabled={isSubmitting || !checked} isLoading={isSubmitting}>Finalizar</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default CreateOcorrency;
