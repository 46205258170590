
import { isDebug, debugApp } from './api';

function getYears() {
  var currentYear = (new Date()).getFullYear();
  var years = [];

  for (var i = 0; i <= 5; i++) {
    years.push(currentYear - i);
  }

  return years;
}

function isDashboard() {
  const urlParams = new URLSearchParams(window.location.search);
  return window.location.pathname.toLowerCase().trim() === "/dashboard" && (urlParams.get("Token") && urlParams.get("Token") === "329832o23kbdkebjkedg83746");
}

function is_popvp() {
  return (isDebug ? debugApp === "povp" : isSubDomain("povp"));
}

function is_aminharua() {
  return (isDebug ? debugApp === "aminharua" : isSubDomain("aminharua"));
}

function isSubDomain(name) {
  const subDomain = window.location.hostname.split(".");
  console.log(subDomain[0]);
  if (subDomain[0].toLowerCase().trim() === name.toLowerCase().trim()) {
    return true;
  }

  return false;
}

function nifIsValid(txt) {
  if (!txt || txt.length === 0) {
    return false;
  }

  if (txt.length !== 9) {
    return false;
  }

  for (var i = 0; i < 9; i++) {
    var str = txt.substring(i, (i + 1));

    if (parseInt(str) < 0 || parseInt(str) > 9) {
      return false;
    }
  }

  var Dig1 = parseInt(txt.substring(0, 1));
  var Dig2 = parseInt(txt.substring(1, 2));
  var Dig3 = parseInt(txt.substring(2, 3));
  var Dig4 = parseInt(txt.substring(3, 4));
  var Dig5 = parseInt(txt.substring(4, 5));
  var Dig6 = parseInt(txt.substring(5, 6));
  var Dig7 = parseInt(txt.substring(6, 7));
  var Dig8 = parseInt(txt.substring(7, 8));
  var Dig9 = parseInt(txt.substring(8, 9));

  var Soma = (Dig1 * 9) +
    (Dig2 * 8) +
    (Dig3 * 7) +
    (Dig4 * 6) +
    (Dig5 * 5) +
    (Dig6 * 4) +
    (Dig7 * 3) +
    (Dig8 * 2);

  var Reminder = (11 - (Soma % 11));
  var CD = (Reminder > 9 ? 0 : parseInt(Reminder));

  return (Dig9 === CD);
}

function emailIsValid(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export { getYears, isDashboard, is_popvp, is_aminharua, isSubDomain, nifIsValid, emailIsValid }